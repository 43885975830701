import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { applyTokenRefreshInterceptor } from '@datamap/common-ui'
import axios from 'axios'
import { getEdgesForVoting, getImagesForVoting, getAllUserVotes, hasUserCompleteInitSurvey, joinGroup } from './api'
import localStorageService from './services/localStorageService'
import { useNavigate, useSearchParams } from 'react-router'
import { getCityCode } from './cities'

import 'mapbox-gl/dist/mapbox-gl.css'
import './App.css'

const AppContext = createContext(null)

const App = ({ children }) => {
	const navigate = useNavigate()
	const [searchParams, setSearchParams] = useSearchParams()
	const isVotingDataLoading = useRef(false)
	const [isVotingDataLoaded, setIsVotingDataLoaded] = useState(false)
	const [isUserVotesLoaded, setIsUserVotesLoaded] = useState(false)
	const [allVotingData, setAllVotingData] = useState([])
	const [imagesVotingData, setImagesVotingData] = useState([])
	const [mapVotingData, setMapVotingData] = useState([])
	const [votedData, setVotedData] = useState({})
	const [user, setUser] = useState(localStorageService.getUser())
	const [isAdmin, setIsAdmin] = useState(false)
	const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)
	const [isUserDialogOpen, setIsUserDialogOpen] = useState(false)
	const { basePath } = getCityCode()

	useEffect(() => {
		// Initialize Axios interceptors
		const requestInterceptors = [
			axios.interceptors.request.use(
				config => {
					if (user?.jwt) {
						config.headers['Authorization'] = config.headers['Authorization'] ?? user?.jwt
					}
					if (user?.user_id) {
						config.params = { ...config.params, user_id: user?.user_id }
					}
					return config
				},
				error => Promise.reject(error),
			),
		]
		const responseInterceptors = [
			applyTokenRefreshInterceptor(axios, {
				idServerUrl: process.env.REACT_APP_ID_SERVER,
				updateToken: token => updateUser({ jwt: token }),
			}),
			axios.interceptors.response.use(
				response => response,
				error => {
					if (
						error.response.status === 401 ||
						(error.response.status === 422 && error.response.data?.messageId === 31)
					) {
						clearUser()
					}

					return Promise.reject(error)
				},
			),
		]

		// Set login state
		setIsUserLoggedIn(!!user?.jwt)

		return () => {
			requestInterceptors.forEach(interceptor => axios.interceptors.request.eject(interceptor))
			responseInterceptors.forEach(interceptor => axios.interceptors.response.eject(interceptor))
		}
	}, [user?.jwt, user?.user_id])

	useEffect(() => {
		setIsAdmin(localStorageService.isAdmin())

		if (isUserLoggedIn) {
			handleLogin()
		} else {
			handleLogout()
		}

		// Toggle dialog open state in a timeout. There seems to be some race condition in react 19.0.0 and
		// @mui/material 6.4.8. The state can change quickly during initial rendering, for example
		// false -> false -> true -> false. Although the modal is closed, "overflow: hidden;" is not removed
		// from the body, blocking scrolling.
		// TODO: Test with new versions when available if this is fixed.
		// Reproduce: 1. Refresh page when logged in 2. Open any Menu/Select that uses Popover 3. Close Popover
		setTimeout(() => {
			setIsUserDialogOpen(!isUserLoggedIn)
		})
	}, [isUserLoggedIn])

	const getLastVotes = async () => {
		const { data } = await getAllUserVotes()

		const votedData = {}
		for (const d of data) {
			const ogcFid = d.network_ogc_fid
			const createdAt = d.created_at
			const safety = d.safety
			const conflict = d.conflict
			const attractiveness = d.attractiveness
			const globalVote = d.global_vote
			const imageId = d.image_id
			const imageURL = d.image_url

			if (!votedData[ogcFid]) {
				const newData = { ...d, globalVote, imagesVoting: {} }

				if (imageId) {
					newData.imagesVoting[imageId] = {
						image_url: imageURL,
						image_name: d.image_name,
					}

					if (safety) {
						newData.imageSafety = +safety
						newData.imageSafetyCreatedAt = createdAt

						newData.imagesVoting[imageId].imageSafety = +safety
						newData.imagesVoting[imageId].imageSafetyCreatedAt = createdAt
					}

					if (conflict) {
						newData.imageConflict = +conflict
						newData.imageConflictCreatedAt = createdAt

						newData.imagesVoting[imageId].imageConflict = +conflict
						newData.imagesVoting[imageId].imageConflictCreatedAt = createdAt
					}

					if (attractiveness) {
						newData.imageAttractiveness = +attractiveness
						newData.imageAttractivenessCreatedAt = createdAt

						newData.imagesVoting[imageId].imageAttractiveness = +attractiveness
						newData.imagesVoting[imageId].imageAttractivenessCreatedAt = createdAt
					}

					if (globalVote) {
						newData.imageGlobalVote = +globalVote
						newData.imageGlobalVoteCreatedAt = createdAt

						newData.imagesVoting[imageId].imageGlobalVote = +globalVote
						newData.imagesVoting[imageId].imageGlobalVoteCreatedAt = createdAt
					}
				}

				votedData[ogcFid] = newData
			} else {
				const savedData = votedData[ogcFid]
				const isCurrentSafetyAfterSaved = createdAt > (savedData.safetyCreatedAt || savedData.created_at)
				const isCurrentConflictAfterSaved = createdAt > (savedData.conflictCreatedAt || savedData.created_at)
				const isCurrentAttractivenessAfterSaved = createdAt > (savedData.attractivenessCreatedAt || savedData.created_at)
				const isCurrentGlobalVoteAfterSaved = createdAt > (savedData.globalVoteCreatedAt || savedData.created_at)

				if (imageId) {
					if (!savedData.imagesVoting[imageId]) {
						savedData.imagesVoting[imageId] = {
							image_url: imageURL,
							image_name: d.image_name,
						}
					}

					const savedImagesData = savedData.imagesVoting[imageId]

					savedData.image_id = imageId
					savedData.image_url = imageURL
					if (safety && (!savedData.imageSafety || isCurrentSafetyAfterSaved)) {
						savedData.imageSafety = +safety
						savedData.imageSafetyCreatedAt = createdAt
					}

					if (conflict && (!savedData.imageConflict || isCurrentConflictAfterSaved)) {
						savedData.imageConflict = +conflict
						savedData.imageConflictCreatedAt = createdAt
					}

					if (attractiveness && (!savedData.imageAttractiveness || isCurrentAttractivenessAfterSaved)) {
						savedData.imageAttractiveness = +attractiveness
						savedData.imageAttractivenessCreatedAt = createdAt
					}

					if (globalVote && (!savedData.imageGlobalVote || isCurrentGlobalVoteAfterSaved)) {
						savedData.imageGlobalVote = +globalVote
						savedData.imageGlobalVoteCreatedAt = createdAt
					}

					// user can vote only once on image for one crtiteria
					if (safety) {
						savedImagesData.imageSafety = +safety
						savedImagesData.imageSafetyCreatedAt = createdAt
					}

					if (conflict) {
						savedImagesData.imageConflict = +conflict
						savedImagesData.imageConflictCreatedAt = createdAt
					}

					if (attractiveness) {
						savedImagesData.imageAttractiveness = +attractiveness
						savedImagesData.imageAttractivenessCreatedAt = createdAt
					}

					if (globalVote) {
						savedImagesData.imageGlobalVote = +globalVote
						savedImagesData.imageGlobalVoteCreatedAt = createdAt
					}
				} else {
					if (safety && (!savedData.safety || isCurrentSafetyAfterSaved)) {
						savedData.safety = +safety
						savedData.safetyCreatedAt = createdAt
					}

					if (conflict && (!savedData.conflict || isCurrentConflictAfterSaved)) {
						savedData.conflict = +conflict
						savedData.conflictCreatedAt = createdAt
					}

					if (attractiveness && (!savedData.attractiveness || isCurrentAttractivenessAfterSaved)) {
						savedData.attractiveness = +attractiveness
						savedData.attractivenessCreatedAt = createdAt
					}

					if (globalVote && (!savedData.globalVote || isCurrentGlobalVoteAfterSaved)) {
						savedData.globalVote = +globalVote
						savedData.globalVoteCreatedAt = createdAt
					}
				}
			}
		}

		setVotedData(votedData)
		setIsUserVotesLoaded(true)
	}

	const fetchVotingData = async () => {
		// Only fetch voting data once even if this function is triggered multiple times
		if (isVotingDataLoading.current) {
			return
		} else {
			isVotingDataLoading.current = true
		}

		const [{ data: edgesForVoting }, { data: imagesForVoting }] = await Promise.all([
			getEdgesForVoting(),
			getImagesForVoting(),
		])

		if (edgesForVoting?.features?.length > 0) {
			const routesForVote = edgesForVoting.features.map(f => ({ type: 'FeatureCollection', features: [f] }))
			const imagesVotingData = imagesForVoting.features.map(f => ({ type: 'FeatureCollection', features: [f] }))
			const mapVotingOgcFid = {}
			let mapFeatures = []
			for (let i = 0; i < edgesForVoting.features.length; i++) {
				const feature = edgesForVoting.features[i]
				const ogcFid = feature?.properties?.ogc_fid
				if (!mapVotingOgcFid[ogcFid]) {
					mapVotingOgcFid[ogcFid] = true
					mapFeatures.push({ ...feature, id: i })
				}
			}

			const mapVotingData = {
				type: 'FeatureCollection',
				features: mapFeatures,
			}

			// get unique images
			const uniqueImages = {}
			for (const votingData of imagesVotingData) {
				const imageId = votingData.features[0]?.properties?.image_id
				uniqueImages[imageId] = votingData
			}

			const uniqueImagesArray = Object.values(uniqueImages)

			// get unique edges
			const uniqueEdges = {}
			for (const votingData of uniqueImagesArray) {
				const newImageId = votingData.features[0]?.properties?.image_id
				const ogc_fid = votingData.features[0]?.properties?.ogc_fid
				const existingData = uniqueEdges[ogc_fid]
				if (existingData?.features) {
					const existingImageId = existingData.features[0]?.properties?.image_id
					if (newImageId > existingImageId) {
						uniqueEdges[ogc_fid] = votingData
					}
				} else {
					uniqueEdges[ogc_fid] = votingData
				}
			}

			const uniqueEdgesImagesArray = Object.values(uniqueEdges).map(e => ({
				...e,
				randomNumber: Math.floor(Math.random() * 10000),
			}))

			const sortedUniqueEdgesImagesArray = uniqueEdgesImagesArray.sort((a, b) => {
				const priorityA = a?.features[0]?.properties?.priority
				const idA = a?.randomNumber
				const priorityB = b?.features[0]?.properties?.priority
				const idB = b?.randomNumber

				if (priorityA === 0) {
					if (priorityB === 0) {
						return idA - idB
					}

					return 1
				}

				if (priorityB === 0) {
					return -1
				}

				if (priorityA === priorityB) {
					return idA - idB
				}

				return priorityA - priorityB
			})

			setAllVotingData(routesForVote)
			setImagesVotingData(sortedUniqueEdgesImagesArray)
			setMapVotingData(mapVotingData)
		}

		setIsVotingDataLoaded(true)
	}

	// Image voting
	const onGlobalImageVoted = data => {
		const now = Date.now()

		const ogcFid = data.ogcFid
		if (votedData[ogcFid]) {
			const savedData = { ...votedData[ogcFid] }
			savedData.imageGlobalVote = +data.mark
			savedData.imageGlobalVoteCreatedAt = now
			savedData.image_id = data.imageId
			savedData.image_url = data.imageURL
			if (!savedData.imagesVoting[data.imageURL]) {
				savedData.imagesVoting[data.imageURL] = {
					image_url: data.imageURL,
					image_name: data.imageName,
					imageGlobalVote: +data.mark,
					imageGlobalVoteCreatedAt: now,
				}
			} else {
				savedData.imagesVoting[data.imageURL] = {
					...savedData.imagesVoting[data.imageURL],
					image_url: data.imageURL,
					image_name: data.imageName,
					imageGlobalVote: +data.mark,
					imageGlobalVoteCreatedAt: now,
				}
			}

			setVotedData({ ...votedData, [ogcFid]: savedData })
		} else {
			const newData = {
				imageGlobalVote: +data.mark,
				imageGlobalVoteCreatedAt: now,
				image_id: data.imageId,
				image_url: data.imageURL,
				imagesVoting: {
					[data.imageId]: {
						image_url: data.imageURL,
						image_name: data.imageName,
						imageGlobalVote: +data.mark,
						imageGlobalVoteCreatedAt: now,
					},
				},
			}

			setVotedData({ ...votedData, [ogcFid]: newData })
		}
	}

	const onSafetyImageVoted = data => {
		const now = Date.now()

		const ogcFid = data.ogcFid
		if (votedData[ogcFid]) {
			const savedData = { ...votedData[ogcFid] }
			savedData.imageSafety = +data.mark
			savedData.imageSafetyCreatedAt = now
			savedData.image_id = data.imageId
			savedData.image_url = data.imageURL
			if (!savedData.imagesVoting[data.imageURL]) {
				savedData.imagesVoting[data.imageURL] = {
					image_url: data.imageURL,
					image_name: data.imageName,
					imageSafety: +data.mark,
					imageSafetyCreatedAt: now,
				}
			} else {
				savedData.imagesVoting[data.imageURL] = {
					...savedData.imagesVoting[data.imageURL],
					image_url: data.imageURL,
					image_name: data.imageName,
					imageSafety: +data.mark,
					imageSafetyCreatedAt: now,
				}
			}

			setVotedData({ ...votedData, [ogcFid]: savedData })
		} else {
			const newData = {
				imageSafety: +data.mark,
				imageSafetyCreatedAt: now,
				image_id: data.imageId,
				image_url: data.imageURL,
				imagesVoting: {
					[data.imageId]: {
						image_url: data.imageURL,
						image_name: data.imageName,
						imageSafety: +data.mark,
						imageSafetyCreatedAt: now,
					},
				},
			}

			setVotedData({ ...votedData, [ogcFid]: newData })
		}
	}

	const onConflictImageVoted = data => {
		const now = Date.now()

		const ogcFid = data.ogcFid
		if (votedData[ogcFid]) {
			const savedData = { ...votedData[ogcFid] }
			savedData.imageConflict = +data.mark
			savedData.imageConflictCreatedAt = now
			savedData.image_id = data.imageId
			savedData.image_url = data.imageURL
			if (!savedData.imagesVoting[data.imageURL]) {
				savedData.imagesVoting[data.imageURL] = {
					image_url: data.imageURL,
					image_name: data.imageName,
					imageConflict: +data.mark,
					imageConflictCreatedAt: now,
				}
			} else {
				savedData.imagesVoting[data.imageURL] = {
					...savedData.imagesVoting[data.imageURL],
					image_url: data.imageURL,
					image_name: data.imageName,
					imageConflict: +data.mark,
					imageConflictCreatedAt: now,
				}
			}

			setVotedData({ ...votedData, [ogcFid]: savedData })
		} else {
			const newData = {
				imageConflict: +data.mark,
				imageConflictCreatedAt: now,
				image_id: data.imageId,
				image_url: data.imageURL,
				imagesVoting: {
					[data.imageId]: {
						image_url: data.imageURL,
						image_name: data.imageName,
						imageConflict: +data.mark,
						imageConflictCreatedAt: now,
					},
				},
			}

			setVotedData({ ...votedData, [ogcFid]: newData })
		}
	}

	const onAttractivenessImageVoted = data => {
		const now = Date.now()

		const ogcFid = data.ogcFid
		if (votedData[ogcFid]) {
			const savedData = { ...votedData[ogcFid] }
			savedData.imageAttractiveness = +data.mark
			savedData.imageAttractivenessCreatedAt = now
			savedData.image_id = data.imageId
			savedData.image_url = data.imageURL
			if (!savedData.imagesVoting[data.imageURL]) {
				savedData.imagesVoting[data.imageURL] = {
					image_url: data.imageURL,
					image_name: data.imageName,
					imageAttractiveness: +data.mark,
					imageAttractivenessCreatedAt: now,
				}
			} else {
				savedData.imagesVoting[data.imageURL] = {
					...savedData.imagesVoting[data.imageURL],
					image_url: data.imageURL,
					image_name: data.imageName,
					imageAttractiveness: +data.mark,
					imageAttractivenessCreatedAt: now,
				}
			}

			setVotedData({ ...votedData, [ogcFid]: savedData })
		} else {
			const newData = {
				imageAttractiveness: +data.mark,
				imageAttractivenessCreatedAt: now,
				image_id: data.imageId,
				image_url: data.imageURL,
				imagesVoting: {
					[data.imageId]: {
						image_url: data.imageURL,
						image_name: data.imageName,
						imageAttractiveness: +data.mark,
						imageAttractivenessCreatedAt: now,
					},
				},
			}

			setVotedData({ ...votedData, [ogcFid]: newData })
		}
	}

	// Map voting
	const onGlobalMapVoted = (selecteData, mark) => {
		let newVotedData = { ...votedData }
		for (const newData of selecteData) {
			const ogcFid = newData?.properties?.ogc_fid
			if (votedData[ogcFid]) {
				const savedData = { ...votedData[ogcFid] }
				savedData.globalVote = +mark
				savedData.globalVoteCreatedAt = new Date()

				newVotedData = { ...newVotedData, [ogcFid]: savedData }
			} else {
				const newData = {
					globalVote: +mark,
					globalVoteCreatedAt: new Date(),
				}

				newVotedData = { ...newVotedData, [ogcFid]: newData }
			}
		}

		setVotedData(newVotedData)
	}

	const onClassificationMapVoted = (selecteData, mark, updatingField) => {
		let newVotedData = { ...votedData }
		for (const newData of selecteData) {
			const ogcFid = newData?.properties?.ogc_fid
			if (votedData[ogcFid]) {
				const savedData = { ...votedData[ogcFid] }
				savedData[updatingField] = +mark

				newVotedData = { ...newVotedData, [ogcFid]: savedData }
			} else {
				const newData = {
					[updatingField]: +mark,
				}

				newVotedData = { ...newVotedData, [ogcFid]: newData }
			}
		}

		setVotedData(newVotedData)
	}

	const updateUser = data => {
		localStorageService.setUser(data)
		setUser(localStorageService.getUser())
	}

	const clearUser = () => {
		localStorageService.removeUser()
		setUser(null)
		// Navigate to base path after timeout so that Axios interceptors have time to update
		setTimeout(() => navigate(basePath))
	}

	const handleLogin = () => {
		return Promise.all([
			// Fetch voting data
			getLastVotes(),
			// Update pretest status on login
			(async () => {
				const res = await hasUserCompleteInitSurvey()
				if (res.data && localStorageService.getUser()) {
					// Update if user still exists in local storage and was not removed by a failed request
					updateUser({ isPretestCompleted: !!res?.data?.hasUserCompleteInitSurvey })
				}
			})(),
			// Join a user group if defined in query parameters
			(async () => {
				const slug = searchParams.get('school')
				if (slug) {
					await joinGroup(slug)
				}
			})(),
		])
	}

	const handleLogout = async () => {
		setVotedData({})
		setIsUserVotesLoaded(false)
	}

	const sharedState = {
		allVotingData,
		setAllVotingData,
		imagesVotingData,
		mapVotingData,
		votedData,
		fetchVotingData,
		isVotingDataLoaded,
		isUserVotesLoaded,
		onGlobalImageVoted,
		onSafetyImageVoted,
		onConflictImageVoted,
		onAttractivenessImageVoted,
		onGlobalMapVoted,
		onClassificationMapVoted,
		user,
		updateUser,
		clearUser,
		isAdmin,
		isUserLoggedIn,
		isUserDialogOpen,
		setIsUserDialogOpen,
	}

	return <AppContext.Provider value={sharedState}>{children}</AppContext.Provider>
}

export const useAppContext = () => useContext(AppContext)

export default App
