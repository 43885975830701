import { Link } from 'react-router'
import classNames from 'classnames'
import Icon from '../Icon'
import { getCityCode, TOP_LEVEL_DOMAIN } from '../../cities'
import Strings from '../../strings'

import styles from './MoreMenu.module.css'

const MoreMenu = ({ hideDesktop }) => {
	const { basePath } = getCityCode()

	return (
		<div className={classNames(styles.menuContainer, { [styles.hideDesktop]: hideDesktop })}>
			<a href={`https://velobserver.${TOP_LEVEL_DOMAIN}`} target='_blank' className={styles.menuItem}>
				<Icon name='home' />
				VelObserver.{TOP_LEVEL_DOMAIN}
			</a>
			<Link to={`${basePath}/menu`} className={styles.menuItem}>
				{Strings.getMessage('MenuPage.Title')}
			</Link>
			<Link to={`${basePath}/menu/challenge`} className={styles.menuItem}>
				{Strings.getMessage('Challenge.Faq')}
			</Link>
		</div>
	)
}

export default MoreMenu
