import { Fragment, useEffect, useState } from 'react'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import MainMenu from '../../components/MainMenu'
import DesktopMenu from '../../components/DesktopMenu'
import Strings from '../../strings'
import Input from '../../components/Input'
import CustomRadioGroup from '../../components/CustomRadioGroup'
import InputContainer from '../../components/InputContainer'
import Button from '../../components/Button'
import { useAppContext } from '../../App'
import { getUserData, updateUser, updateUserPhoto } from '../../api'
import Icon from '../../components/Icon'

import styles from './Profile.module.css'

const Profile = () => {
	const { isUserLoggedIn } = useAppContext()
	const [isFetching, setIsFetching] = useState(true)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isSubmittingAvatar, setIsSubmittingAvatar] = useState(false)
	const [isDeletingAvatar, setIsDeletingAvatar] = useState(false)
	const [userData, setUserData] = useState(null)
	const [avatar, setAvatar] = useState(null)
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [username, setUsername] = useState('')
	const [gender, setGender] = useState('')
	const [birthYear, setBirthYear] = useState('')

	const isLoading = isFetching || isSubmitting || isSubmittingAvatar || isDeletingAvatar
	const isSubmitDisabled =
		isLoading ||
		(firstName === (userData.name ?? '') &&
			lastName === (userData.last_name ?? '') &&
			username === (userData.alias ?? '') &&
			gender === (userData.gender?.toString() ?? '') &&
			birthYear === (userData.birthyear?.toString() ?? ''))

	useEffect(() => {
		if (isUserLoggedIn) {
			fetchUserData()
		}
	}, [isUserLoggedIn])

	const fetchUserData = async () => {
		const userData = await getUserData()
		setUserData(userData)
		if (userData) {
			setFirstName(userData.name ?? '')
			setLastName(userData.last_name ?? '')
			setUsername(userData.alias ?? '')
			setGender(userData.gender?.toString() ?? '')
			setBirthYear(userData.birthyear?.toString() ?? '')
			setAvatar(userData.photo ?? null)
		}
		setIsFetching(false)
	}

	const handleAvatarUpload = async event => {
		const file = event.target.files[0]
		if (file) {
			setIsSubmittingAvatar(true)
			const result = await updateUserPhoto({ file })
			setAvatar(result.photo)
			setIsSubmittingAvatar(false)
		}
	}

	const handleAvatarDelete = async () => {
		setIsDeletingAvatar(true)
		const result = await updateUser({ photo: null })
		if (result) {
			setUserData(result)
			setAvatar(result.photo)
		}
		setIsDeletingAvatar(false)
	}

	const handleUserDataSubmit = async event => {
		event.preventDefault()

		setIsSubmitting(true)
		const result = await updateUser({
			name: firstName || null,
			last_name: lastName || null,
			alias: username || null,
			gender: gender ? parseInt(gender) : null,
			birthyear: birthYear ? parseInt(birthYear) : null,
		})
		if (result) {
			setUserData(result)
		}
		setIsSubmitting(false)
	}

	return (
		<Fragment>
			<div className={styles.container}>
				<img className={styles.logo} src='/images/logo.svg' alt='Logo' />
				<DesktopMenu />
				<h1 className={styles.title}>{Strings.getMessage('Profile.Title')}</h1>
				<form onSubmit={handleUserDataSubmit} className={styles.form}>
					<div className={styles.inputContainer}>
						<div className={styles.avatarContainer}>
							<div className={styles.avatar}>
								{avatar ? <img src={avatar} alt='Avatar' /> : <Icon name='profile' fill='white' />}
							</div>
							<input
								id='avatar'
								type='file'
								accept='image/*'
								onChange={handleAvatarUpload}
								className={styles.avatarInput}
								disabled={isLoading}
							/>
							<div className={styles.avatarButtons}>
								<Button labelFor='avatar' silver disabled={isLoading} isLoading={isSubmittingAvatar}>
									{Strings.getMessage('Profile.UploadAvatar')}
								</Button>
								{avatar && (
									<Button
										cherry
										disabled={isLoading}
										isLoading={isDeletingAvatar}
										onClick={handleAvatarDelete}
										Icon={DeleteOutlineIcon}></Button>
								)}
							</div>
						</div>
						<Input
							value={username}
							onChange={setUsername}
							label={Strings.getMessage('Username')}
							className={styles.input}
						/>
						<Input
							value={firstName}
							onChange={setFirstName}
							label={Strings.getMessage('Name')}
							className={styles.input}
						/>
						<Input
							value={lastName}
							onChange={setLastName}
							label={Strings.getMessage('LastName')}
							className={styles.input}
						/>
						<InputContainer label={Strings.getMessage('Survey.PersonalInfo.Gender')}>
							<CustomRadioGroup
								value={gender}
								onChange={setGender}
								name='gender'
								options={[
									{ name: '1', label: Strings.getMessage('Survey.PersonalInfo.Female') },
									{ name: '0', label: Strings.getMessage('Survey.PersonalInfo.Male') },
									{ name: '2', label: Strings.getMessage('Survey.PersonalInfo.Diverse') },
								]}
								row
								className={styles.input}
							/>
						</InputContainer>
						<InputContainer label={Strings.getMessage('Survey.PersonalInfo.YearPlaceholder')}>
							<select
								value={birthYear}
								onChange={event => setBirthYear(event.target.value)}
								className={styles.input}>
								<option disabled value=''>
									{Strings.getMessage('Survey.PersonalInfo.YearPlaceholder')}
								</option>
								{Array.from({ length: 150 }, (_, i) => (
									// Generate 150 year options beginning from current year
									<option key={i}>{new Date().getFullYear() - i}</option>
								))}
								{birthYear && parseInt(birthYear) <= new Date().getFullYear() - 150 && (
									// If current birth year is too old, add it as disabled option
									<option disabled>{birthYear}</option>
								)}
							</select>
						</InputContainer>
					</div>
					<Button type='submit' apple disabled={isSubmitDisabled} isLoading={isSubmitting}>
						{Strings.getMessage('SaveOnComputer')}
					</Button>
				</form>
			</div>
			<MainMenu />
		</Fragment>
	)
}

export default Profile
