import styles from './InputContainer.module.css'

const InputContainer = ({ children, label }) => {
	return (
		<div className={styles.container}>
			<label className={styles.label}>{label}</label>
			{children}
		</div>
	)
}

export default InputContainer
