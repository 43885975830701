import { Fragment } from 'react'
import Icon from '../../components/Icon'
import MainMenu from '../../components/MainMenu'
import { useLocation } from 'react-router'
import DesktopMenu from '../../components/DesktopMenu'
import Strings from '../../strings'
import MoreMenu from '../../components/MoreMenu'

import styles from './Menu.module.css'

const Menu = () => {
	const location = useLocation()

	const copyToClipboard = suffix => {
		const host = window.location.origin
		const pathname = location.pathname

		const url = `${host}${pathname}#${suffix}`
		navigator.clipboard.writeText(url)
	}

	return (
		<Fragment>
			<div className={styles.container}>
				<DesktopMenu />
				<img className={styles.logo} src='/images/logo.svg' alt='Logo' />
				<MoreMenu hideDesktop />
				<div className={styles.content}>
					<div>
						<h1 className={styles.title}>{Strings.getMessage('MenuPage.Title')}</h1>
						<ol className={styles.mainList}>
							<li className={styles.mainListItem}>
								<a href='#was-ist-velobserver'>{Strings.getMessage('MenuPage.Question1')}</a>
							</li>
							<li className={styles.mainListItem}>
								<a href='#wie-funktioniert-die-bewertung'>{Strings.getMessage('MenuPage.Question2')}</a>
							</li>
							<li className={styles.mainListItem}>
								<a href='#was-bedeuten-die-kriterien'>{Strings.getMessage('MenuPage.Question3')}</a>
							</li>
							<ul className={styles.subList}>
								<li className={styles.subListItem}>
									<a href='#sicherheit'>{Strings.getMessage('Security')}</a>
								</li>
								<li className={styles.subListItem}>
									<a href='#konfliktfreiheit'>{Strings.getMessage('FreedomFromConflict')}</a>
								</li>
								<li className={styles.subListItem}>
									<a href='#attraktivitat'>{Strings.getMessage('Attractiveness')}</a>
								</li>
							</ul>
							<li className={styles.mainListItem}>
								<a href='#was-kann-ich-tun'>{Strings.getMessage('MenuPage.Question4')}</a>
							</li>
							<li className={styles.mainListItem}>
								<a href='#was-macht-ihr-mit-meinen-bewertungen'>{Strings.getMessage('MenuPage.Question5')}</a>
							</li>
							<li className={styles.mainListItem}>
								<a href='#warum-soll-ich-weitere-fragen-uber-mich-beantworten'>
									{Strings.getMessage('MenuPage.Question6')}
								</a>
							</li>
							<li className={styles.mainListItem}>
								<a href='#wer-steht-hinter-velobserver'>{Strings.getMessage('MenuPage.Question7')}</a>
							</li>
							<li className={styles.mainListItem}>
								<a href='#was-kann-ich-tun-um-euch'>{Strings.getMessage('MenuPage.Question8')}</a>
							</li>
						</ol>
					</div>
					<div>
						<div id='was-ist-velobserver' className={styles.subtitle}>
							<h2>{Strings.getMessage('MenuPage.Question1')}</h2>
							<div onClick={() => copyToClipboard('was-ist-velobserver')} title='Copy to clipboard'>
								<Icon name='anchor' />
							</div>
						</div>
						<div className={styles.description}>{Strings.getMessage('MenuPage.Answer1', { stringOnly: false })}</div>

						<div id='wie-funktioniert-die-bewertung' className={styles.subtitle}>
							<h2>{Strings.getMessage('MenuPage.Question2')}</h2>
							<div onClick={() => copyToClipboard('wie-funktioniert-die-bewertung')} title='Copy to clipboard'>
								<Icon name='anchor' />
							</div>
						</div>
						<div className={styles.description}>{Strings.getMessage('MenuPage.Answer2', { stringOnly: false })}</div>

						<div id='was-bedeuten-die-kriterien' className={styles.subtitle}>
							<h2>{Strings.getMessage('MenuPage.Question3')}</h2>
							<div onClick={() => copyToClipboard('was-bedeuten-die-kriterien')} title='Copy to clipboard'>
								<Icon name='anchor' />
							</div>
						</div>
						<div className={styles.description}>
							{Strings.getMessage('MenuPage.Answer3.1', { stringOnly: false })}
							<h3 id='sicherheit'>{Strings.getMessage('Security')}</h3>
							{Strings.getMessage('MenuPage.Answer3.2', { stringOnly: false })}
							<h3 id='konfliktfreiheit'>{Strings.getMessage('FreedomFromConflict')}</h3>
							{Strings.getMessage('MenuPage.Answer3.3', { stringOnly: false })}
							<h3 id='attraktivitat'>{Strings.getMessage('Attractiveness')}</h3>
							{Strings.getMessage('MenuPage.Answer3.4', { stringOnly: false })}
						</div>

						<div id='was-kann-ich-tun' className={styles.subtitle}>
							<h2>{Strings.getMessage('MenuPage.Question4')}</h2>
							<div onClick={() => copyToClipboard('was-kann-ich-tun')} title='Copy to clipboard'>
								<Icon name='anchor' />
							</div>
						</div>
						<div className={styles.description}>{Strings.getMessage('MenuPage.Answer4', { stringOnly: false })}</div>

						<div id='was-macht-ihr-mit-meinen-bewertungen' className={styles.subtitle}>
							<h2>{Strings.getMessage('MenuPage.Question5')}</h2>
							<div
								onClick={() => copyToClipboard('was-macht-ihr-mit-meinen-bewertungen')}
								title='Copy to clipboard'>
								<Icon name='anchor' />
							</div>
						</div>
						<div className={styles.description}>{Strings.getMessage('MenuPage.Answer5', { stringOnly: false })}</div>

						<div id='warum-soll-ich-weitere-fragen-uber-mich-beantworten' className={styles.subtitle}>
							<h2>{Strings.getMessage('MenuPage.Question6')}</h2>
							<div
								onClick={() => copyToClipboard('warum-soll-ich-weitere-fragen-uber-mich-beantworten')}
								title='Copy to clipboard'>
								<Icon name='anchor' />
							</div>
						</div>
						<div className={styles.description}>{Strings.getMessage('MenuPage.Answer6', { stringOnly: false })}</div>

						<div id='wer-steht-hinter-velobserver' className={styles.subtitle}>
							<h2>{Strings.getMessage('MenuPage.Question7')}</h2>
							<div onClick={() => copyToClipboard('wer-steht-hinter-velobserver')} title='Copy to clipboard'>
								<Icon name='anchor' />
							</div>
						</div>
						<div className={styles.description}>{Strings.getMessage('MenuPage.Answer7', { stringOnly: false })}</div>

						<div id='was-kann-ich-tun-um-euch' className={styles.subtitle}>
							<h2>{Strings.getMessage('MenuPage.Question8')}</h2>
							<div onClick={() => copyToClipboard('was-kann-ich-tun-um-euch')} title='Copy to clipboard'>
								<Icon name='anchor' />
							</div>
						</div>
						<div className={styles.description}>{Strings.getMessage('MenuPage.Answer8', { stringOnly: false })}</div>
					</div>
				</div>
			</div>
			<MainMenu />
		</Fragment>
	)
}

export default Menu
