import FormControl from '@mui/material/FormControl'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'

export default function CustomRadioGroup({ name, onChange, options, value, className, row }) {
	return (
		<FormControl component='fieldset' variant='standard' className={className}>
			<RadioGroup aria-label={name} name={name} value={value} onChange={event => onChange(event.target.value)} row={row}>
				{options &&
					options.length &&
					options.map(option => {
						return (
							<div style={{ marginBottom: 8 }} key={option.name + '_key'}>
								<FormControlLabel
									key={option.name}
									value={option.name}
									control={<Radio size={'small'} style={{ color: '#2d2047' }} />}
									labelPlacement='end'
									label={
										<Typography style={{ fontFamily: 'DM Sans', fontSize: 12 }}>{option.label}</Typography>
									}
								/>
								{option.explanation ? (
									<div
										style={{
											fontSize: 9,
											color: 'rgba(0, 0, 0, 0.54)',
											margin: '0px 0 0 26px',
											padding: 0,
										}}>
										{option.explanation}
									</div>
								) : (
									''
								)}
							</div>
						)
					})}
			</RadioGroup>
		</FormControl>
	)
}
