import { CITY_BASEL, CITY_ZUERICH, getCityCode } from '../cities'
import Request from './request'

const SERVER = process.env.REACT_APP_API
const ID_SERVER = process.env.REACT_APP_ID_SERVER
const DATAMAP_API = process.env.REACT_APP_DATAMAP_API

const STRAPI_SERVER = process.env.REACT_APP_STRAPI_SERVER_URL
const STRAPI_SUBSCRIBE_EMAIL = `${STRAPI_SERVER}/subscribe-email`

export function hasUserCompleteInitSurvey() {
	return Request.get({
		url: `${SERVER}/v1/hasUserCompleteInitSurvey`,
	})
}

export function getAllUserVotes() {
	return Request.get({
		url: `${SERVER}/v1/getAllUserVotes`,
	})
}

export function getEdgesForVoting() {
	return Request.get({
		url: `${SERVER}/v1/getEdgesForVoting`,
	})
}

export function getImagesForVoting() {
	return Request.get({
		url: `${SERVER}/v1/getImagesForVoting`,
	})
}

export function voteEdges(data) {
	return Request.post({
		url: `${SERVER}/v1/voteEdges`,
		data,
	})
}

export function addRouteImages(data) {
	return Request.post({
		url: `${SERVER}/v1/addRouteImages`,
		data,
	})
}

export function updateImageStreetName(data) {
	return Request.put({
		url: `${SERVER}/v1/updateImageStreetName`,
		data,
	})
}

export function subscribeEmail(email) {
	return Request.put({ data: { email, source: 'velobserver_app' }, url: STRAPI_SUBSCRIBE_EMAIL })
}

export const login = async data => {
	const response = await Request.post({
		url: `${ID_SERVER}/api/user/login`,
		data,
		headers: { 'Client-Api-Key': process.env.REACT_APP_CLIENT_API_KEY },
	})
	return response.data
}

export const signup = async data => {
	const response = await Request.post({
		url: `${ID_SERVER}/api/user/register`,
		data,
		headers: { 'Client-Api-Key': process.env.REACT_APP_CLIENT_API_KEY },
	})
	return response.data
}

export const forgotPassword = async data => {
	const response = await Request.post({
		url: `${ID_SERVER}/api/user/init-reset-password`,
		data,
		headers: { 'Client-Api-Key': process.env.REACT_APP_CLIENT_API_KEY },
	})
	return response.data
}

export const resetPassword = async data => {
	const response = await Request.post({
		url: `${ID_SERVER}/api/user/reset-password`,
		data,
		headers: { 'Client-Api-Key': process.env.REACT_APP_CLIENT_API_KEY },
	})
	return response.data
}

export const getUserDataFromIDServer = async () => {
	const response = await Request.get({ url: `${ID_SERVER}/api/user/data` })
	return response.data
}

export const refreshToken = async token => {
	const response = await Request.post({
		url: `${ID_SERVER}/api/auth/refresh-token`,
		headers: { Authorization: token },
	})
	return response.data
}

export const routeJourney = async (data, isRouteEditorRouting) => {
	const { city } = getCityCode()
	try {
		return (
			await Request.post({
				url: `${SERVER}/v1/journey/routing`,
				data,
				params: { temporary: [CITY_ZUERICH].includes(city) && !isRouteEditorRouting },
			})
		).data
	} catch (e) {
		console.error(e)
		return { type: 'FeatureCollection', features: [] }
	}
}

export const saveUserRoute = async data => {
	try {
		return (
			await Request.post({
				url: `${SERVER}/v1/userRoutes`,
				data,
			})
		).data
	} catch (e) {
		console.error(e)
		return { type: 'FeatureCollection', features: [] }
	}
}

export const updateUserRoute = async (routeId, data) => {
	try {
		return (
			await Request.put({
				url: `${SERVER}/v1/userRoutes/${routeId}`,
				data,
			})
		).data
	} catch (e) {
		console.error(e)
		return { type: 'FeatureCollection', features: [] }
	}
}

export const getUserRoutes = async userId => {
	try {
		return (
			await Request.get({
				url: `${SERVER}/v1/userRoutes`,
				params: { user_id: userId },
			})
		).data
	} catch (e) {
		console.error(e)
		return { type: 'FeatureCollection', features: [] }
	}
}

export const deleteUserRoute = async ({ routeId }) => {
	try {
		return (
			await Request.delete({
				url: `${SERVER}/v1/userRoutes/${routeId}`,
			})
		).data
	} catch (e) {
		console.error(e)
		return { type: 'FeatureCollection', features: [] }
	}
}

export const getPlace = async ({ lat, lon }) => {
	try {
		return (
			await Request.get({
				url: `${DATAMAP_API}/api/posmo/nominatim/reverse/${lat}/${lon}`,
			})
		).data
	} catch (e) {
		console.error(e)
		return {
			type: 'FeatureCollection',
			features: [],
		}
	}
}

export const getUserPlaces = async ({ userId }) => {
	try {
		const userPlaces = (
			await Request.get({
				url: `${DATAMAP_API}/api/posmo/nodes/${userId}`,
			})
		).data

		return {
			type: 'FeatureCollection',
			features: userPlaces.data
				.map(place => {
					return {
						type: 'Feature',
						geometry: {
							type: 'Point',
							coordinates: [place.lon, place.lat],
						},
						properties: {
							id: place.id,
							name: place.alias_name || place.node_name,
							full_address:
								place.street && place.house_number
									? `${place.street} ${place.house_number}, ${place.postcode ? place.postcode : ''} ${
											place.city ? place.city : ''
										}, ${place.country ? place.country : ''}`
									: '',
							idle_id: place.idle_id,
						},
					}
				})
				.sort((a, b) => {
					return a.properties.idle_id - b.properties.idle_id
				}),
		}
	} catch (e) {
		console.error(e)
		return {
			type: 'FeatureCollection',
			features: [],
		}
	}
}

export const saveUserPlace = async ({ userId, node }) => {
	try {
		const place = (
			await Request.post({
				url: `${DATAMAP_API}/api/posmo/node`,
				data: { user_id: userId, node },
			})
		).data.data

		return {
			type: 'FeatureCollection',
			features: [
				{
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: [place.lon, place.lat],
					},
					properties: {
						id: place.id,
						name: place.alias_name || place.node_name,
						full_address:
							place.street && place.house_number
								? `${place.street} ${place.house_number}, ${place.postcode ? place.postcode : ''} ${
										place.city ? place.city : ''
									}, ${place.country ? place.country : ''}`
								: '',
						idle_id: place.idle_id,
					},
				},
			],
		}
	} catch (e) {
		console.error(e)
		return {
			type: 'FeatureCollection',
			features: [],
		}
	}
}

export const getPlaceFromAddress = async ({ address, proximity = 'ip' }) => {
	try {
		const { city } = getCityCode()
		const country = city === 'zuerich' || city === 'basel' ? 'ch' : null
		let url = `${DATAMAP_API}/api/search/getPlaceFromAddress?q=${encodeURIComponent(address)}&proximity=${proximity}`
		if (country) {
			url += `&country=${country}`
		}
		return (
			await Request.get({
				url,
			})
		).data
	} catch (e) {
		console.error(e)
		return {
			type: 'FeatureCollection',
			features: [],
		}
	}
}

export const getCountries = async language => {
	try {
		const response = await Request.get({ url: `${DATAMAP_API}/api/posmo/countries`, params: { language } })
		return response.data.data
	} catch (e) {
		console.error(e)
		return []
	}
}

export const getEdges = async veloplan => {
	const response = await Request.get({ url: `${SERVER}/v1/getEdges`, params: { veloplan } })
	return response.data
}

export const createRoute = async data => {
	const response = await Request.post({ url: `${SERVER}/v1/createRoute`, data })
	return response.data
}

export const updateRouteProps = async data => {
	const response = await Request.post({ url: `${SERVER}/v1/updateRouteProps`, data })
	return response.data
}

export const updateRouteEdges = async (routeId, edges) => {
	const response = await Request.post({ url: `${SERVER}/v1/updateRouteEdges`, data: { route_id: routeId, edges } })
	return response.data
}

export const getNetworkRoutes = async () => {
	const response = await Request.get({ url: `${SERVER}/v1/getNetworkRoutes` })
	return response.data
}

export const updateEdgesStatus = async data => {
	const response = await Request.post({ url: `${SERVER}/v1/updateEdgesStatus`, data })
	return response.data
}

export const getCityserverProjects = async () => {
	try {
		const response = await Request.get({
			url: `${ID_SERVER}/api/public-cityserver-projects`,
		})
		return response.data
	} catch (e) {
		console.error('Error fetching public cityserver projects:', e)
		throw e // Re-throw the error for the caller to handle
	}
}

export const joinGroup = async slug => {
	try {
		await Request.post({ url: `${SERVER}/v1/userGroups/join`, data: { slug } })
	} catch (e) {
		console.log(e)
	}
}

export const getUserData = async () => {
	const response = await Request.get({ url: `${ID_SERVER}/api/user/data` })
	return response.data.data
}

export const updateUser = async data => {
	const response = await Request.post({ url: `${ID_SERVER}/api/user/update`, data })
	return response.data.data
}

export const updateUserPhoto = async data => {
	const response = await Request.post({
		url: `${ID_SERVER}/api/user/update/photo`,
		headers: { 'Content-Type': 'multipart/form-data' },
		data,
	})
	return response.data.data
}

export const getChallenges = async () => {
	const response = await Request.get({ url: `${SERVER}/v1/challenges` })
	return response.data
}

export const getChallenge = async challengeIdentifier => {
	const response = await Request.get({ url: `${SERVER}/v1/challenges/${challengeIdentifier}` })
	return response.data
}

export const getChallengeChecklist = async (challengeIdentifier, userId) => {
	const response = await Request.get({
		url: `${SERVER}/v1/challenges/${challengeIdentifier}/checklist`,
		params: { user_id: userId },
	})
	return response.data
}
