import { Fragment } from 'react'
import MainMenu from '../../components/MainMenu'
import DesktopMenu from '../../components/DesktopMenu'
import Strings from '../../strings'
import MoreMenu from '../../components/MoreMenu'
import { AVAILABLE_CITIES, getCityCode } from '../../cities'

import styles from '../Menu/Menu.module.css'

const ChallengeFaq = () => {
	const { basePath, city } = getCityCode()

	const cityProperties = AVAILABLE_CITIES[city]
	const trackingProjectCode = cityProperties.trackingProjectCode ? ` **${cityProperties.trackingProjectCode}**` : ''
	const trackingProjectCodeWithSuffix = trackingProjectCode
		? `${trackingProjectCode} ${Strings.getMessage('Challenge.Faq.TrackingProjectCodeSuffix')}`
		: ''

	return (
		<Fragment>
			<div className={styles.container}>
				<DesktopMenu />
				<img className={styles.logo} src='/images/logo.svg' alt='Logo' />
				<MoreMenu hideDesktop />
				<div className={styles.content}>
					<h1 className={styles.title}>{Strings.getMessage('Challenge.Faq.Title')}</h1>
					<div className={styles.description}>
						{Strings.getMessage('Challenge.Faq.Description', {
							stringOnly: false,
							replacements: {
								trackingProjectCode,
								trackingProjectCodeWithSuffix,
								challengeLink: `${basePath}/challenge`,
								challengeLinkText: `${window.location.origin}${basePath}/challenge`,
								myRoutesLink: `${basePath}?showMyRoutes=true`,
								myRoutesLinkText: `${window.location.origin}${basePath}?showMyRoutes=true`,
							},
						})}
					</div>
				</div>
			</div>
			<MainMenu />
		</Fragment>
	)
}

export default ChallengeFaq
