import { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router'
import { getChallenges } from '../../api'
import { useAppContext } from '../../App'
import Strings from '../../strings'
import DesktopMenu from '../../components/DesktopMenu'
import MainMenu from '../../components/MainMenu'
import { getFormattedDate } from '../../utils'
import { getCityCode } from '../../cities'

import styles from './ChallengeList.module.css'

const ChallengeList = () => {
	const navigate = useNavigate()
	const { isUserLoggedIn } = useAppContext()
	const [challenges, setChallenges] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	const { basePath } = getCityCode()

	useEffect(() => {
		if (isUserLoggedIn) {
			fetchChallenges()
		}
	}, [isUserLoggedIn])

	const fetchChallenges = async () => {
		const challenges = await getChallenges()
		// Sort challenges by end time, or start time if end time is null
		challenges.sort((a, b) => {
			const a_end_at = a.end_at ? parseInt(a.end_at) : Infinity
			const b_end_at = b.end_at ? parseInt(b.end_at) : Infinity
			if (a_end_at !== b_end_at) {
				return b_end_at - a_end_at
			} else {
				return parseInt(b.start_at) - parseInt(a.start_at)
			}
		})

		const now = Date.now()
		const activeChallenges = challenges.filter(
			challenge => now >= parseInt(challenge.start_at) && (!challenge.end_at || now <= parseInt(challenge.end_at)),
		)
		const pastChallenges = challenges.filter(challenge => challenge.end_at && now > parseInt(challenge.end_at))

		// If there is only one preferred challenge, navigate to it immediately
		let preferredChallenge
		if (activeChallenges.length === 1) {
			// one active challenge
			preferredChallenge = activeChallenges[0]
		} else if (activeChallenges.length === 0 && pastChallenges.length === 1) {
			// one past challenge and no active ones
			preferredChallenge = pastChallenges[0]
		} else if (challenges.length === 1) {
			// only one challenge (active, past, future)
			preferredChallenge = challenges[0]
		}
		if (preferredChallenge) {
			// Do not use relative navigation, because StrictMode will trigger it twice on development
			return navigate(`${basePath}/challenge/${preferredChallenge.code}`)
		}

		// Otherwise show challenges in a list
		setChallenges(challenges)
		setIsLoading(false)
	}

	return (
		<Fragment>
			<div className={styles.container}>
				<img className={styles.logo} src='/images/logo.svg' alt='Logo' />
				<DesktopMenu />
				<h1 className={styles.title}>{Strings.getMessage('Challenge.Menu')}</h1>
				{!isLoading && challenges.length === 0 ? (
					<div className={styles.challenge}>{Strings.getMessage('Challenge.NoChallenges')}</div>
				) : (
					<div className={styles.challengeList}>
						{challenges.map(challenge => (
							<Link to={challenge.code} className={styles.challenge} key={challenge.id}>
								<div className={styles.titleContainer}>
									<h2 className={styles.challengeTitle}>{challenge.name}</h2>
									<span className={styles.date}>
										{getFormattedDate(challenge.start_at, challenge.start_at_offset)}
										{' - '}
										{getFormattedDate(challenge.end_at, challenge.end_at_offset)}
									</span>
								</div>
								<p className={styles.description}>{challenge.description}</p>
							</Link>
						))}
					</div>
				)}
			</div>
			<MainMenu />
		</Fragment>
	)
}

export default ChallengeList
