import { useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import UserDialog from '../components/UserDialog'
import PretestDialog from '../components/PretestDialog'
import { getCityCode } from '../cities'
import App from '../App'

const Root = () => {
	const location = useLocation()
	const { basePath } = getCityCode()

	const theme = createTheme({
		typography: {
			fontFamily: 'inherit',
		},
		palette: {
			text: {
				primary: '#2d2047',
			},
		},
	})

	useEffect(() => {
		// If hash is provided in the URL in first render, scroll to the element
		// react-router does not handle this automatically
		const hash = window.location.hash.slice(1)
		if (hash) {
			document.getElementById(hash)?.scrollIntoView({ behavior: 'smooth' })
		}
	}, [])

	return location.pathname === '/' ? (
		<Navigate to={basePath} replace />
	) : (
		<ThemeProvider theme={theme}>
			<App>
				<Outlet />
				<UserDialog />
				<PretestDialog />
			</App>
		</ThemeProvider>
	)
}

export default Root
