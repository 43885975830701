import { Fragment, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TimelapseIcon from '@mui/icons-material/Timelapse'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import CancelIcon from '@mui/icons-material/Cancel'
import classNames from 'classnames'
import { getChallenge, getChallengeChecklist } from '../../api'
import { useAppContext } from '../../App'
import Strings from '../../strings'
import MainMenu from '../../components/MainMenu'
import DesktopMenu from '../../components/DesktopMenu'
import Icon from '../../components/Icon'
import { generateColors, getFormattedDate } from '../../utils'
import { getCityCode } from '../../cities'

import styles from './Challenge.module.css'

const Challenge = () => {
	const navigate = useNavigate()
	const { challengeIdentifier } = useParams()
	const { isUserLoggedIn, user } = useAppContext()
	const { basePath } = getCityCode()
	const [challenge, setChallenge] = useState(null)
	const [challengeChecklist, setChallengeChecklist] = useState(null)
	const randomColors = useMemo(() => generateColors(challenge?.leaderboard?.length ?? 0), [challenge])

	const now = Date.now()
	const isActive = challenge && now >= parseInt(challenge.start_at) && (!challenge.end_at || now <= parseInt(challenge.end_at))
	const daysLeft = challenge?.end_at ? Math.ceil((parseInt(challenge.end_at) - now) / (1000 * 60 * 60 * 24)) : 0

	const checklistCompletion = challengeChecklist
		? Object.values(challengeChecklist).filter(Boolean).length / Object.values(challengeChecklist).length
		: 0
	// Hide checklist if challenge is active and checklist is completed
	const showChecklist = !(isActive && checklistCompletion === 1)

	useEffect(() => {
		if (isUserLoggedIn) {
			fetchChallenge()
			fetchChallengeChecklist()
		}
	}, [isUserLoggedIn])

	const fetchChallenge = async () => {
		try {
			const challenge = await getChallenge(challengeIdentifier)
			setChallenge(challenge)
		} catch (error) {
			if (error.response?.status === 404) {
				navigate('..', { replace: true })
			} else {
				console.error(error)
				throw error
			}
		}
	}

	const fetchChallengeChecklist = async () => {
		try {
			const checklist = await getChallengeChecklist(challengeIdentifier, user?.user_id)
			setChallengeChecklist(checklist)
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<Fragment>
			<div className={styles.container}>
				<img className={styles.logo} src='/images/logo.svg' alt='Logo' />
				<DesktopMenu />
				<div className={styles.cardContainer}>
					{challengeChecklist && showChecklist && (
						<Accordion
							className={styles.accordion}
							defaultExpanded={checklistCompletion < 1}
							slotProps={{ heading: { component: 'h2' } }}>
							<AccordionSummary
								className={styles.accordionSummary}
								expandIcon={<ExpandMoreIcon />}
								slotProps={{ content: { className: classNames(styles.checklistItem, styles.checklistTitle) } }}>
								{checklistCompletion === 1 ? (
									<CheckCircleIcon style={{ fontSize: 36, color: '#83b200' }} />
								) : checklistCompletion === 0 ? (
									<CancelIcon style={{ fontSize: 36, color: '#ff565d' }} />
								) : (
									<ErrorIcon style={{ fontSize: 36, color: '#ffaa00' }} />
								)}
								{Strings.getMessage(
									checklistCompletion === 1
										? 'Challenge.Checklist.Completed'
										: 'Challenge.Checklist.NotCompleted',
								)}
							</AccordionSummary>
							<AccordionDetails className={styles.accordionDetails}>
								<div className={styles.checklistContainer}>
									{[
										[null, 'Challenge.Checklist.Tracking'],
										['belongsToTrackingProject', 'Challenge.Checklist.BelongsToTrackingProject'],
										[null, 'Challenge.Checklist.Nodes'],
										['hasHomeNode', 'Challenge.Checklist.HasHomeNode'],
										['hasSchoolNode', 'Challenge.Checklist.HasSchoolNode'],
										[null, 'Challenge.Checklist.Routes'],
										['hasInboundRoute', 'Challenge.Checklist.HasInboundRoute'],
										['hasOutboundRoute', 'Challenge.Checklist.HasOutboundRoute'],
										[null, 'Challenge.Checklist.SafeRoutes'],
										['hasInboundSafeRoute', 'Challenge.Checklist.HasInboundSafeRoute'],
										['hasOutboundSafeRoute', 'Challenge.Checklist.HasOutboundSafeRoute'],
									].map(([key, message]) => (
										<div
											key={key ?? message}
											className={classNames(styles.checklistItem, { [styles.checklistSubtitle]: !key })}>
											{challengeChecklist[key] ? (
												<CheckCircleIcon style={{ fontSize: 36, color: '#83b200' }} />
											) : (
												<CancelIcon style={{ fontSize: 36, color: '#ff565d' }} />
											)}
											{Strings.getMessage(message)}
										</div>
									))}
								</div>
							</AccordionDetails>
						</Accordion>
					)}
					{challenge && (
						<div className={styles.card}>
							<div className={styles.titleContainer}>
								<h2 className={styles.challengeTitle}>{challenge.name}</h2>
								<div className={styles.dateContainer}>
									<span className={styles.date}>
										{getFormattedDate(challenge.start_at, challenge.start_at_offset)}
										{' - '}
										{getFormattedDate(challenge.end_at, challenge.end_at_offset)}
									</span>
									{isActive && daysLeft > 0 && (
										<span className={styles.daysLeft}>
											<TimelapseIcon style={{ fontSize: 20 }} /> {daysLeft}{' '}
											{Strings.getMessage(daysLeft === 1 ? 'Day' : 'Day.Plural')}
										</span>
									)}
								</div>
							</div>
							<p className={styles.description}>
								👉 <Link to={`${basePath}/menu/challenge`}>{Strings.getMessage('Challenge.Faq')}</Link>
							</p>
							<hr />
							<table className={styles.leaderboardTable}>
								<tbody>
									{challenge.leaderboard.map((user, index) => (
										<tr key={index}>
											<td className={styles.rankingCell}>{index + 1}</td>
											<td className={styles.userCell}>
												<span className={styles.userContainer}>
													<span
														className={styles.avatar}
														style={{ backgroundColor: randomColors[index] }}>
														{user.photo ? (
															<img src={user.photo} alt='Avatar' />
														) : (
															<Icon name='profile' fill='white' className={styles.avatarIcon} />
														)}
													</span>
													<span className={styles.username}>{user.username}</span>
												</span>
											</td>
											{/* Round to 1 decimal without trailing zeros */}
											<td className={styles.scoreCell}>{+user.score.toFixed(1)} SHP</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					)}
				</div>
			</div>
			<MainMenu />
		</Fragment>
	)
}

export default Challenge
