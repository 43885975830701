import { Fragment, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import MobileStepper from '@mui/material/MobileStepper'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import MultipleOptions from './MultipleOptions'
import CustomRadioGroup from './CustomRadioGroup'
import PersonalData from './PersonalData'
import localStorageService from '../services/localStorageService'
import UsageChoice, { TRANSPORT_TYPES } from './UsageChoice'
import skipPersonaSuvey from '../api/skipPersonaSuvey'
import axios from 'axios'
import Strings from '../strings'
import { getUserDataFromIDServer } from '../api'
import { getCityCode, TOP_LEVEL_DOMAIN } from '../cities'
import { useAppContext } from '../App'
import { useNavigate } from 'react-router'

import styles from './PretestStepper.module.css'

const steps = [
	{
		label: (
			<span>
				{Strings.getMessage('Survey.Title--1/2')} <br />
				{Strings.getMessage('Survey.Title--2/2')}
			</span>
		),
		body: (
			<Fragment>
				<p>{Strings.getMessage('Survey.StepIntro.Description')}</p>
				<p>{Strings.getMessage('Survey.StepIntro.ListParagraph')}</p>
				<ul>
					<li>{Strings.getMessage('Survey.StepIntro.ListItem')}</li>
				</ul>
				<p>{Strings.getMessage('Survey.StepIntro.Footer')}</p>
			</Fragment>
		),
	},
	{
		label: Strings.getMessage('Survey.PersonalInfo.Label'),
		body: ({ personalData, setPersonalData, user }) => <PersonalData data={personalData} onChange={setPersonalData} />,
	},
	{
		label: Strings.getMessage('Survey.ThankYou.Label'),
		body: (
			<Fragment>
				<p>{Strings.getMessage('Survey.ThankYou.Description')}</p>
				<p>{Strings.getMessage('Survey.ThankYou.Footer')}</p>
			</Fragment>
		),
	},
	{
		label: Strings.getMessage('Survey.HowOftenDoYouUse.Label'),
		body: ({ sliderState, setSliderState, updateSlider, max, setMax, updateUsageChoice, userUsageChoices }) => (
			<UsageChoice userUsageChoices={userUsageChoices} updateUsageChoice={updateUsageChoice} />
		),
	},
	{
		label: Strings.getMessage('Survey.WhichBike.Label'),
		body: ({ bike, setBike }) => (
			<MultipleOptions
				init={bike}
				key='bike'
				onChange={bike => {
					setBike(bike)
				}}
				options={[
					{ name: `citybike`, label: Strings.getMessage('Survey.WhichBike.EverydayBike') },
					{ name: `e-bike25km`, label: Strings.getMessage('Survey.WhichBike.E-Bike25') },
					{ name: `e-bike45km`, label: Strings.getMessage('Survey.WhichBike.E-Bike45') },
					{ name: `sportvelo`, label: Strings.getMessage('Survey.WhichBike.SportsBike') },
					{ name: `tourenbike`, label: Strings.getMessage('Survey.WhichBike.Travel') },
					{ name: 'cargobike', label: Strings.getMessage('Survey.WhichBike.Cargo') },
					{ name: 'bikesharing', label: Strings.getMessage('Survey.WhichBike.Sharing') },
					{ name: `andere`, label: Strings.getMessage('Survey.WhichBike.Other') },
				]}
			/>
		),
	},
	{
		label: Strings.getMessage('Survey.WhatPurpose.Label'),
		body: ({ usage, setUsage }) => (
			<MultipleOptions
				init={usage}
				key='usage'
				onChange={usage => {
					setUsage(usage)
				}}
				options={[
					{ name: `arbeit`, label: Strings.getMessage('Survey.WhatPurpose.Work') },
					{ name: `ausbildung`, label: Strings.getMessage('Survey.WhatPurpose.Education') },
					{ name: `freizeit_kultur_sport`, label: Strings.getMessage('Survey.WhatPurpose.Leisure') },
					{
						name: `einkauf_gastronomie_dienstleistungen`,
						label: Strings.getMessage('Survey.WhatPurpose.Shopping'),
					},
					{ name: `ferien-reisen`, label: Strings.getMessage('Survey.WhatPurpose.Holidays') },
					{ name: `andere`, label: Strings.getMessage('Survey.WhatPurpose.Other') },
				]}
			/>
		),
	},
	{
		label: Strings.getMessage('Survey.AreThereChildren.Label'),
		body: ({ children, setChildren }) => (
			<CustomRadioGroup
				name='children'
				key='children'
				value={children}
				onChange={setChildren}
				options={[
					{ name: `ja`, label: Strings.getMessage('Survey.AreThereChildren.Yes') },
					{ name: 'nein', label: Strings.getMessage('Survey.AreThereChildren.No') },
					{ name: `keine_angabe`, label: Strings.getMessage('Survey.AreThereChildren.NoInfo') },
				]}
			/>
		),
	},
]

export default function PretestStepper() {
	const navigate = useNavigate()
	const theme = useTheme()
	const { user, updateUser } = useAppContext()
	const [activeStep, setActiveStep] = useState(0)
	const maxSteps = steps.length - 3
	const { basePath } = getCityCode()

	const [finished, setFinished] = useState(false)
	const [error, setError] = useState(false)

	useEffect(() => {
		getUserData()
	}, [])

	const getUserData = async () => {
		try {
			const response = await getUserDataFromIDServer()
			const data = response.data
			updateUser(data)
			setPersonalData({
				birthyear: data.birthyear,
				gender: data.gender === 0 ? 'male' : data.gender === 1 ? 'female' : data.gender === 2 ? 'Other' : false,
				city: data.city,
				country_code: data.country_code,
				postcode: data.postcode,
			})
			setSignupData({
				email: data.email,
			})
		} catch (err) {
			console.log(err)
		}
	}

	const [sliderState, setSliderState] = useState({
		walking: { pct: 25, lock: false, id: 'walking' },
		biking: { pct: 25, lock: false, id: 'biking' },
		pt: { pct: 25, lock: false, id: 'pt' },
		car: { pct: 25, lock: false, id: 'car' },
	})

	const [userUsageChoices, setUserUsageChoices] = useState({})

	const [max, setMax] = useState(100)

	const [bike, setBike] = useState({})
	const [usage, setUsage] = useState({})
	const [children, setChildren] = useState('')
	const [personalData, setPersonalData] = useState({
		birthyear: '',
		postcode: '',
		city: '',
		country_code: '',
		gender: false,
	})
	const [signupData, setSignupData] = useState({
		email: '',
		password: '',
	})

	const isNextDisabled = activeStep => {
		switch (activeStep) {
			case 0:
				return false
			case 1:
				for (let o in personalData) {
					if (!!personalData[o] === false) {
						return true
					}
				}
				return false
			case 2:
				return false
			case 3:
				for (let transportType of TRANSPORT_TYPES) {
					if (userUsageChoices[transportType.key] === undefined) {
						return true
					}
				}

				return false
			case 4:
				return false
			case 5:
				return false
			case 6:
				return !children
			case 7:
				return false
			default:
				return true
		}
	}

	const updateUsageChoice = (name, value) => {
		setUserUsageChoices({ ...userUsageChoices, [name]: value })
	}

	const updateSlider = (id, pct) => {
		const ids = ['walking', 'biking', 'pt', 'car']

		const targets = ids.filter(origin => {
			return origin !== id && sliderState[origin].lock === false
		})
		const toDistribute = pct - sliderState[id].pct

		let next = 0
		if (Math.abs(toDistribute / targets.length) >= 0) {
			targets.forEach((target, i) => {
				if (i === 0 && toDistribute % targets.length !== 0) {
					sliderState[target].pct -= toDistribute % targets.length
				}

				if (sliderState[target].pct - parseInt(toDistribute / targets.length) < 0) {
					next += sliderState[target].pct - parseInt(toDistribute / targets.length)
					sliderState[target].pct = 0
				} else {
					sliderState[target].pct -= parseInt(toDistribute / targets.length)
				}

				document.querySelector(`#${target} .MuiSlider-thumb`).style.left = sliderState[target].pct + '%'
				document.querySelector(`#${target} .MuiSlider-thumb span span span`).innerHTML = sliderState[target].pct

				document.querySelector(`#${target} .MuiSlider-track`).style.width = sliderState[target].pct + '%'
				document.querySelectorAll(`#${target} .MuiSlider-markLabel`).forEach(item => {
					if (parseInt(item.style.left) > sliderState[target].pct) {
						item.classList.remove('MuiSlider-markLabelActive')
					}
				})
			})

			if (Math.abs(next) > 0) {
				for (let i = 0; i < targets.length; i++) {
					const target = targets[i]
					if (sliderState[target].pct + next >= 0) {
						sliderState[target].pct += next

						document.querySelector(`#${target} .MuiSlider-thumb`).style.left = sliderState[target].pct + '%'
						document.querySelector(`#${target} .MuiSlider-track`).style.width = sliderState[target].pct + '%'
						document.querySelectorAll(`#${target} .MuiSlider-markLabel`).forEach(item => {
							if (parseInt(item.style.left) > sliderState[target].pct) {
								item.classList.remove('MuiSlider-markLabelActive')
							}
						})
						break
					}
				}
			}
		}

		sliderState[id].pct = pct
		setSliderState({ ...sliderState })
	}

	const handleNext = () => {
		setActiveStep(prevActiveStep => prevActiveStep + 1)
	}

	const handleBack = () => {
		setError(false)
		setActiveStep(prevActiveStep => prevActiveStep - 1)
	}

	const onAbort = () => {
		skipPersonaSuvey()
			.then(data => data.data)
			.then(persona => {
				const { data } = persona
				if (data.length) {
					const dict = {}
					data.forEach(d => {
						if (!dict[d.topic]) {
							dict[d.topic] = {}
						}

						dict[d.topic] = { ...dict[d.topic], [d.key]: d.value }
					})
					localStorageService.setUserPretestPanel(dict)
					updateUser({ isPretestCompleted: true })
				}
			})
			.catch(err => {
				console.log(err)
			})
	}

	const onGoToRating = () => {
		skipPersonaSuvey()
			.then(data => data.data)
			.then(persona => {
				const { data } = persona
				if (data.length) {
					const dict = {}
					data.forEach(d => {
						if (!dict[d.topic]) {
							dict[d.topic] = {}
						}

						dict[d.topic] = { ...dict[d.topic], [d.key]: d.value }
					})
					localStorageService.setUserPretestPanel(dict)
					updateUser({ isPretestCompleted: true })
					navigate(`${basePath}/general-rating-photos`)
				}
			})
			.catch(err => {
				console.log(err)
			})
	}

	const handleFinish = () => {
		const data = {
			bike_type: bike,
			bike_usage: usage,
			children: { [children]: 1 },
			transport_usage: userUsageChoices,
			user: {
				...signupData,
				...personalData,
				id: user.id,
			},
		}
		// store pretest data to localstorage
		localStorageService.setUserPretestPanel(data)

		// update datamap account
		return axios
			.post(
				`${process.env.REACT_APP_ID_SERVER}/api/user/update`,
				{ ...data.user, gender: data.user.gender === 'male' ? 0 : data.user.gender === 'female' ? 1 : 2 },
				{
					headers: {
						Authentication: localStorageService.getUser().jwt,
					},
				},
			)
			.then(() => {
				// do another call to store data mapping id to email on posmo coop server
				const { city } = getCityCode()
				return axios.post(`${process.env.REACT_APP_API}/v1/addPersona`, data, { headers: { city } })
			})
			.then(() => {
				setError(false)
				setFinished(true)
				updateUser({ isPretestCompleted: true })
			})
			.catch(err => {
				console.log(err)
				setError(<Typography style={{ color: 'red' }}>Error!</Typography>)
			})
	}

	return (
		<div className={styles.pretestContainer}>
			<img className={styles.logo} src='/images/logo.svg' alt='Logo' />
			<Typography style={{ color: '#83b200', fontFamily: 'Recoleta', fontSize: 24, fontWeight: 600, marginTop: 16 }}>
				{finished ? 'Besten Dank für deine Anmeldung!' : steps[activeStep].label}
			</Typography>
			{error && error}
			{!finished ? (
				<div className={styles.stepContainer}>
					{typeof steps[activeStep].body === 'function'
						? steps[activeStep].body({
								sliderState,
								setSliderState,
								updateSlider,
								max,
								setMax,
								bike,
								setBike,
								usage,
								setUsage,
								children,
								setChildren,
								personalData,
								setPersonalData,
								signupData,
								setSignupData,
								user,
								updateUsageChoice,
								userUsageChoices,
							})
						: steps[activeStep].body}
				</div>
			) : (
				<p style={{ fontSize: 12 }}>
					{Strings.getMessage('Survey.Finis.Text--1/3')}{' '}
					<a href={`https://velobserver.${TOP_LEVEL_DOMAIN}`}>{Strings.getMessage('Survey.Finis.Text--2/3')}</a>{' '}
					{Strings.getMessage('Survey.Finis.Text--3/3')}
				</p>
			)}
			{activeStep === 0 && !finished && (
				<div className={styles.bottomContainer}>
					<Button className={styles.abortButton} type='button' onClick={onAbort}>
						{Strings.getMessage('Abort')}
					</Button>
					<Button
						style={{
							fontWeight: 600,
							fontFamily: 'Recoleta',
							color: '#eee',
							backgroundColor: '#83b200',
							fontSize: 14,
							textTransform: 'none',
						}}
						onClick={handleNext}
						variant='contained'>
						{Strings.getMessage('Continue')}
					</Button>
				</div>
			)}
			{activeStep === 1 && !finished && (
				<div className={styles.bottomContainer}>
					<Button className={styles.abortButton} type='button' onClick={onAbort}>
						{Strings.getMessage('Abort')}
					</Button>
					<Button
						className={styles.nextButton}
						disabled={isNextDisabled(activeStep)}
						onClick={handleNext}
						variant='contained'>
						{Strings.getMessage('SaveOnComputer')}
					</Button>
				</div>
			)}
			{activeStep === 2 && !finished && (
				<div className={styles.bottomContainer}>
					<Button className={styles.abortButton} type='button' onClick={onGoToRating}>
						{Strings.getMessage('Survey.StepIntro.ForEvaluation')}
					</Button>
					<Button
						style={{
							fontWeight: 600,
							fontFamily: 'Recoleta',
							color: '#eee',
							backgroundColor: '#83b200',
							fontSize: 14,
							textTransform: 'none',
						}}
						disabled={isNextDisabled(activeStep)}
						onClick={handleNext}
						variant='contained'>
						{Strings.getMessage('Survey.StepIntro.ToTheQuestions')}
					</Button>
				</div>
			)}
			{!finished && activeStep > 2 && (
				<MobileStepper
					variant='dots'
					classes={{ root: styles.stepper, dotActive: styles.stepperDot }}
					steps={maxSteps}
					position='static'
					activeStep={activeStep - 3}
					nextButton={
						activeStep === maxSteps + 2 ? (
							<Button
								id='finished'
								disabled={isNextDisabled(activeStep)}
								style={{ fontWeight: 600, fontSize: 14, fontFamily: 'Recoleta', textTransform: 'none' }}
								size='small'
								onClick={handleFinish}>
								{Strings.getMessage('Complete')}
							</Button>
						) : (
							<Button
								style={{ fontWeight: 600, fontSize: 14, textTransform: 'none', fontFamily: 'Recoleta' }}
								key={`step${activeStep}`}
								disabled={isNextDisabled(activeStep)}
								size='small'
								onClick={handleNext}>
								{Strings.getMessage('Continue')} <KeyboardArrowRight />
							</Button>
						)
					}
					backButton={
						<Button
							style={{ fontWeight: 600, fontFamily: 'Recoleta', fontSize: 14, textTransform: 'none' }}
							size='small'
							onClick={handleBack}
							disabled={activeStep === 0}>
							{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
							{Strings.getMessage('Back')}
						</Button>
					}
				/>
			)}
		</div>
	)
}
