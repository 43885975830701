import Markdown from 'react-markdown'
import { Link } from 'react-router'
import remarkMath from 'remark-math'
import rehypeMathjax from 'rehype-mathjax/chtml'
import english from './english'
import german from './german'

const Strings = (() => {
	let language = 'de'

	return {
		getLanguage: () => {
			return language
		},

		setLanguage: newLanguage => {
			language = newLanguage
		},

		getMessage: (messageId, options = { stringOnly: true, replacements: {} }) => {
			const stringOnly = options.stringOnly ?? true
			const replacements = options.replacements ?? {}

			let message
			switch (language) {
				case 'de':
					message = german[messageId]
					break
				case 'en':
					message = english[messageId]
					break
				default:
					message = german[messageId]
			}

			// By default return the message as is without markdown formatting
			if (stringOnly) {
				return message
			}

			// Replace placeholders with actual values
			Object.keys(replacements).forEach(key => {
				const regex = new RegExp(`{{${key}}}`, 'g')
				message = message.replace(regex, replacements[key])
			})

			return (
				<Markdown
					remarkPlugins={[remarkMath]}
					rehypePlugins={[
						[
							rehypeMathjax,
							{
								chtml: {
									fontURL: 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/output/chtml/fonts/woff-v2',
									scale: 1.15,
								},
							},
						],
					]}
					components={{
						// Use react-router Link component for relative links
						a: ({ children, href }) => {
							if (href.startsWith('http') || href.startsWith('mailto')) {
								// External link, open in new tab and do not transmit data to destination site
								return (
									<a href={href} target='_blank' rel='noreferrer'>
										{children}
									</a>
								)
							} else {
								// Relative link
								return <Link to={href}>{children}</Link>
							}
						},
					}}>
					{message}
				</Markdown>
			)
		},
	}
})()

export default Strings
