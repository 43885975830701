import chroma from 'chroma-js'
import mapboxgl from 'mapbox-gl'

export function validateEmail($email) {
	var emailReg = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
	return emailReg.test($email)
}

export function sortArrayLexicographically(arr, sortKey = null, order = 'asc') {
	const sign = order === 'asc' ? 1 : -1

	return arr.sort((a, b) => {
		const elementA = a[sortKey] || a
		const elementB = b[sortKey] || b

		if (elementA > elementB) {
			return 1 * sign
		} else if (elementA < elementB) {
			return -1 * sign
		} else {
			return 0
		}
	})
}

export const MAX_VISIBLE_VOTED_IMAGES = 30

export const generateImageURL = (data, width = 360) => {
	if (data.imageName) {
		return `https://velobserver.imgix.net/${data.imageName}?ar=3:2&fit=crop&w=${width}&auto=format&dpr=2`
	}

	return data.url
}

export const generateImageSrcset = (data, width = 360) => {
	if (data.imageName) {
		return `https://velobserver.imgix.net/${data.imageName}?ar=3:2&fit=crop&w=${width}&auto=format&dpr=1 1x, https://velobserver.imgix.net/${data.imageName}?ar=3:2&fit=crop&w=${width}&auto=format&dpr=2 2x`
	}

	return data.url
}

export const fitToFeatureBounds = (map, features) => {
	// Calculate bounds of features and focus map there
	// https://docs.mapbox.com/mapbox-gl-js/example/zoomto-linestring/
	const mapBounds = features.reduce((bounds, feature) => {
		if (feature.geometry.type === 'Point') {
			bounds.extend(feature.geometry.coordinates, feature.geometry.coordinates)
		} else {
			feature.geometry.coordinates.forEach(coordinate => bounds.extend(coordinate, coordinate))
		}
		return bounds
	}, new mapboxgl.LngLatBounds())
	if (!mapBounds.isEmpty()) {
		map?.fitBounds(mapBounds, { padding: { top: 150, bottom: 150, left: 500, right: 50 } })
	}
}

export const getFormattedDate = (epochMilliseconds, utcOffset = 0) => {
	if (!epochMilliseconds) {
		return ''
	}

	const date = new Date(epochMilliseconds + (utcOffset ?? 0) * 60 * 60 * 1000)
	return `${date.getUTCDate()}.${date.getUTCMonth() + 1}.${date.getUTCFullYear()}`
}

export const generateColors = numColors => {
	var colors = []

	for (var i = 0; i < numColors; i++) {
		var randomColor = chroma.random()
		var contrastRatio = chroma.contrast(randomColor, '#ffffff')

		// Adjust the contrast ratio threshold as needed (4.5 is recommended for text readability)
		while (contrastRatio < 4.5) {
			randomColor = chroma.random()
			contrastRatio = chroma.contrast(randomColor, '#ffffff')
		}

		// Convert the color to hex format and add to the array
		var hexColor = randomColor.hex()
		colors.push(hexColor)
	}

	return colors
}

export const generateDarkerColor = color => {
	return chroma(color).darken(0.5).hex()
}
