import { Fragment, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBikeRounded'
import LoginIcon from '@mui/icons-material/Login'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import Icon from '../Icon'
import cx from 'classnames'
import Strings from '../../strings'
import { AVAILABLE_PATHS, getCityCode, TOP_LEVEL_DOMAIN } from '../../cities'
import { useAppContext } from '../../App'
import MoreMenu from '../MoreMenu'

import styles from './DesktopMenu.module.css'

const DesktopMenu = ({ onBack, data, onNewVote }) => {
	const navigate = useNavigate()
	const location = useLocation()
	const { clearUser, isUserLoggedIn, setIsUserDialogOpen } = useAppContext()
	const [searchParams, setSearchParams] = useSearchParams()
	const [classification, setClassification] = useState(0)
	const [submitting, setSubmitting] = useState(false)
	const [profileMenuAnchor, setProfileMenuAnchor] = useState(null)
	const { basePath } = getCityCode()

	const isProfileMenuOpen = !!profileMenuAnchor

	const onBikeClicked = () => {
		navigate(basePath)
	}

	const onVotingClicked = () => {
		navigate(`${basePath}/${isUserLoggedIn ? 'general-rating' : 'general-rating-photos'}`)
	}

	const onMenuClicked = () => {
		navigate(`${basePath}/menu`)
	}

	const onChallengeClicked = () => {
		navigate(`${basePath}/challenge`)
	}

	const onProfileClicked = () => {
		setProfileMenuAnchor(null)
		navigate(`${basePath}/profile`)
	}

	const onLogoutClicked = () => {
		setProfileMenuAnchor(null)
		clearUser()
	}

	useEffect(() => {
		const classification = searchParams.get('criterion')
		setClassification(classification)
	}, [])

	const onNewVotePressed = async mark => {
		if (onNewVote) {
			setSubmitting(true)
			await onNewVote(mark)
			setSubmitting(false)
		}
	}

	const renderNewVotingAction = () => {
		let badVoteLabel = ''
		let bestVoteLabel = ''
		let title = Strings.getMessage('GeneralRatingMap.HowDoYouRate')
		if (classification === 'safety') {
			badVoteLabel = Strings.getMessage('Unsure')
			bestVoteLabel = Strings.getMessage('Secure')
			title = Strings.getMessage('GeneralRatingMap.HowDoYouFeel')
		}

		if (classification === 'conflict') {
			badVoteLabel = Strings.getMessage('LotsOfConflicts')
			bestVoteLabel = Strings.getMessage('NoConflicts')
			title = Strings.getMessage('GeneralRatingMap.CanYouDrive')
		}

		if (classification === 'attractiveness') {
			badVoteLabel = Strings.getMessage('Unattractive')
			bestVoteLabel = Strings.getMessage('Attractive')
			title = Strings.getMessage('GeneralRatingMap.DoYouFind')
		}

		return (
			<div className={styles.votingActions}>
				<div>
					<span className={styles.segment}>
						{data?.features?.length}{' '}
						{data?.features.length > 1 ? Strings.getMessage('Segments') : Strings.getMessage('Segment')}{' '}
					</span>
					<span>{Strings.getMessage('Selected')}.</span>
				</div>
				<div className={styles.votingContent}>
					<p className={styles.votingText}>{title}</p>
					{!submitting && (
						<>
							<div className={styles.votes}>
								<div onClick={onNewVotePressed.bind(null, 1)}>
									<Icon name='rating_1' />
								</div>
								<div onClick={onNewVotePressed.bind(null, 2)}>
									<Icon name='rating_2' />
								</div>
								<div onClick={onNewVotePressed.bind(null, 3)}>
									<Icon name='rating_3' />
								</div>
								<div onClick={onNewVotePressed.bind(null, 4)}>
									<Icon name='rating_4' />
								</div>
							</div>
						</>
					)}
					{submitting && <div className={styles.voteSubmitting}>{Strings.getMessage('Submitting')}</div>}
					<div className={styles.description}>
						<div className={styles.descriptionBad}>{badVoteLabel}</div>
						<div className={styles.descriptionGood}>{bestVoteLabel}</div>
					</div>
				</div>
			</div>
		)
	}

	const path = location.pathname
	const isRoot = path === basePath
	const isVoting =
		path.startsWith(`${basePath}/general-rating`) ||
		path.startsWith(`${basePath}/general-rating-photos`) ||
		path.startsWith(`${basePath}/general-rating-map`) ||
		path.startsWith(`${basePath}/classification-rating`) ||
		path.startsWith(`${basePath}/classification-rating-photos`) ||
		path.startsWith(`${basePath}/classification-rating-map`)
	const isMenu = path.startsWith(`${basePath}/menu`)
	const isProfile = path.startsWith(`${basePath}/profile`)
	const isChallenge = path.startsWith(`${basePath}/challenge`)

	const bikeCSS = cx(styles.menuLink, {
		[styles.active]: isRoot || AVAILABLE_PATHS.includes(path),
	})
	const bewertenCSS = cx(styles.menuLink, { [styles.active]: isVoting })
	const menuCSS = cx(styles.menuLink, { [styles.active]: isMenu })
	const profileCSS = cx(styles.menuLink, { [styles.active]: isProfile })
	const challengeCSS = cx(styles.menuLink, { [styles.active]: isChallenge })

	const featuresLength = data?.features?.length
	const hidePopup = isRoot
	const showAction = !!featuresLength && !hidePopup

	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				{onBack && isUserLoggedIn ? (
					<Link className={styles.topLink} to={onBack}>
						<Icon name='caret_left' /> <span>{Strings.getMessage('Overview')}</span>
					</Link>
				) : (
					<div className={styles.logo}>
						<a href={`https://velobserver.${TOP_LEVEL_DOMAIN}${basePath}`} target='_blank' rel='noreferrer'>
							<img src='/images/logo.svg' alt='Logo' />
						</a>
					</div>
				)}
				<div className={styles.menu}>
					<button className={bikeCSS} onClick={onBikeClicked}>
						<DirectionsBikeIcon style={{ fontSize: 26 }} />
						<div className={styles.label}>{Strings.getMessage('Route')}</div>
					</button>
					<button className={bewertenCSS} onClick={onVotingClicked}>
						<Icon name='bewerten' />
						<div className={styles.label}>{Strings.getMessage('Evaluete')}</div>
					</button>
					<button className={menuCSS} onClick={onMenuClicked}>
						<Icon name='help' width={24} height={24} />
						<div className={styles.label}>{Strings.getMessage('Faq')}</div>
					</button>
					<button className={challengeCSS} onClick={onChallengeClicked} disabled={!isUserLoggedIn}>
						<EmojiEventsIcon style={{ fontSize: 26 }} />
						<div className={styles.label}>{Strings.getMessage('Challenge.Menu')}</div>
					</button>
					{isUserLoggedIn ? (
						<Fragment>
							<button
								className={profileCSS}
								onClick={event => setProfileMenuAnchor(event.currentTarget)}
								id='profile-button'
								aria-controls={isProfileMenuOpen ? 'profile-menu' : undefined}
								aria-haspopup='true'
								aria-expanded={isProfileMenuOpen ? 'true' : undefined}>
								<Icon name='profile' />
								<div className={styles.label}>{Strings.getMessage('Profile.Title')}</div>
							</button>
							<Menu
								id='profile-menu'
								anchorEl={profileMenuAnchor}
								open={isProfileMenuOpen}
								onClose={() => setProfileMenuAnchor(null)}
								classes={{ paper: styles.profileMenu }}
								MenuListProps={{
									'aria-labelledby': 'profile-button',
								}}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'center',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'center',
								}}>
								<MenuItem onClick={onProfileClicked}>{Strings.getMessage('Profile.Title')}</MenuItem>
								<MenuItem className={styles.logout} onClick={onLogoutClicked}>
									{Strings.getMessage('Logout')}
								</MenuItem>
							</Menu>
						</Fragment>
					) : (
						<button className={styles.menuLink} onClick={() => setIsUserDialogOpen(true)}>
							<LoginIcon style={{ fontSize: 26 }} />
							<div className={styles.label}>{Strings.getMessage('Register')}</div>
						</button>
					)}
				</div>
			</div>
			{showAction && renderNewVotingAction()}
			{isMenu && <MoreMenu />}
		</div>
	)
}

export default DesktopMenu
