import classNames from 'classnames'
import InputContainer from '../InputContainer'

import './style.css'

const Input = ({ className, value, onChange, onChangeWithTarget, placeholder, label, ...props }) => {
	const onChangeInput = event => {
		const value = event.target.rawValue || event.target.value
		onChange && onChange(value)
		onChangeWithTarget && onChangeWithTarget(event)
	}

	return (
		<InputContainer label={label}>
			<input
				className={classNames('Input', className)}
				value={value}
				onChange={onChangeInput}
				placeholder={placeholder}
				{...props}
			/>
		</InputContainer>
	)
}

export default Input
