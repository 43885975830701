import { Dialog as MuiDialog, IconButton, useMediaQuery } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import classNames from 'classnames'

import styles from './Dialog.module.css'

const Dialog = ({ open, onClose = () => undefined, allowClose = true, children, fullScreen = false }) => {
	const isFullScreen = useMediaQuery('(max-width: 440px)') || fullScreen

	return (
		<MuiDialog
			classes={{ paper: classNames(styles.dialog, { [styles.dialogFullScreen]: isFullScreen }) }}
			fullScreen={isFullScreen}
			open={open}
			onClose={onClose}>
			{allowClose && (
				<IconButton className={styles.closeButton} aria-label='close' onClick={onClose}>
					<CloseIcon />
				</IconButton>
			)}
			<div className={styles.dialogContent}>{children}</div>
		</MuiDialog>
	)
}

export default Dialog
