import { useState } from 'react'
import { Link } from 'react-router'
import classNames from 'classnames'
import CircularProgress from '@mui/material/CircularProgress'

import styles from './Button.module.css'

const Button = ({
	href,
	innerHref,
	labelFor,
	onClick,
	children,
	apple,
	cherry,
	silver,
	className,
	fit,
	disabled,
	type,
	isLoading,
	Icon,
}) => {
	const [isDisabled, setIsDisabled] = useState(false)
	const css = classNames(className, styles.button, {
		[styles.apple]: apple,
		[styles.cherry]: cherry,
		[styles.fit]: fit,
		[styles.link]: innerHref || href,
		[styles.silver]: silver,
		[styles.disabled]: disabled,
		[styles.icon]: Icon,
	})

	const onButtonClicked = async e => {
		if (onClick) {
			setIsDisabled(true)
			await onClick(e)
			setIsDisabled(false)
		}
	}

	const Component = innerHref ? Link : href ? 'a' : labelFor ? 'label' : 'button'

	return (
		<Component
			className={css}
			to={innerHref}
			href={href}
			htmlFor={labelFor}
			onClick={onButtonClicked}
			disabled={isDisabled || disabled}
			type={type}>
			<div className={classNames(styles.buttonContent, { [styles.isLoading]: isLoading })}>
				{children}
				{Icon && <Icon fontSize='small' />}
				<div className={styles.progressContainer}>{isLoading && <CircularProgress className={styles.progress} />}</div>
			</div>
		</Component>
	)
}

export default Button
